import React from 'react';
import { Button, CardContent, CardMedia, Grid, Link as MuiLink, Typography } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { GlassCard } from "components/layout/glass-card";
import { Product } from "models/schemas/product";

export type ProductCardProps = {
    product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {

    const { summary, page, wordmark } = product;

    return (
        <GlassCard>
            <>
                <CardMedia
                    sx={{ height: 140, width: "100%" }}
                    image={wordmark?.url}
                    title={wordmark?.alt}
                    component={"img"}
                />
                <CardContent>
                    <Grid container justifyContent={"center"} alignContent={"space-between"} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"body2"} align={"center"}
                                        color={"rgba(255,255,255,0.7)"}>{summary}</Typography>
                        </Grid>
                        <Grid item container justifyContent={"flex-end"}>
                            <Grid item>
                                <Button
                                    className={"text-animated-gradient"}
                                    component={page.link.isInternal ? GatsbyLink : MuiLink}
                                    to={page.link.url}
                                    href={page.link.url}
                                >
                                    Find out more</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </>
        </GlassCard>
    )
}
export { ProductCard }